import React from "react";
import {Box, Button, Icon, Input, TextField, Typography} from "@mui/material";
import {UseScreenSelectors} from "../../../../../Utilities/ScreenSize";
import {ReactComponent as CloudSVG} from "../../../../Assets/Cloud.svg";
import {ReactComponent as UploadIconSVG} from "../../../../Assets/UploadIcon.svg";
import RecommendersForm from "./RecommendersForm/RecommendersForm";
import {ErrorOutlineRounded} from "@mui/icons-material";
import {isEmailValid, isPhoneValid} from "./Utilities/regex";
import {
    Candidate,
    generateCandidateId,
    getFilteredCandidates
} from "../../../../../Firebase/FirebaseFunctions/Candidate";
import {getFilteredCandidateJobStatuses} from "../../../../../Firebase/FirebaseFunctions/CandidateJobStatus";
import CustomDialog from "../../../../Components/Dialog/Dialog";
import {Recomendation} from "../../../../../Firebase/FirebaseFunctions/Recomendation";
import {useNavigate} from "react-router-dom";
import GvanimProgress from "../../../../Components/GvanimProgress/GvanimProgress";

const ABOUT_MAX_LENGTH = 1000;
const ARRIVAL_MAX_LENGTH = 50;

export default function ApplicationForm(props: {
    jobApplicationElement: React.RefObject<HTMLParagraphElement>,
    jobNumber
}) {
    const {jobApplicationElement, jobNumber} = props;

    const [loading, setLoading] = React.useState(false);

    const [validateRecommenders, setValidateRecommenders] = React.useState(false);

    const [candidateName, setCandidateName] = React.useState("");
    const [candidateNameError, setCandidateNameError] = React.useState(false);
    const [candidateSurname, setCandidateSurname] = React.useState("");
    const [candidateSurnameError, setCandidateSurnameError] = React.useState(false);
    const [candidatePhone, setCandidatePhone] = React.useState("");
    const [candidatePhoneError, setCandidatePhoneError] = React.useState(false);
    const [candidateEmail, setCandidateEmail] = React.useState("");
    const [candidateEmailError, setCandidateEmailError] = React.useState(false);

    const [aboutNumChars, setAboutNumChars] = React.useState(0);
    const [aboutText, setAboutText] = React.useState("");
    const [arrivalWayChars, setArrivalWayChars] = React.useState(0);
    const [arrivalWayText, setArrivalWayText] = React.useState("");
    const [arrivalWayError, setArrivalWayError] = React.useState(false);

    const cvFileInputRef = React.useRef<HTMLInputElement>(null);
    const [cvFile, setCvFile] = React.useState<File | null>(null);
    const [cvFileError, setCvFileError] = React.useState(false);
    const [cvFileExtensionError, setCvFileExtensionError] = React.useState(false);

    const [alertMessage, setAlertMessage] = React.useState("");
    const [alert, setAlert] = React.useState(false);

    const navigate = useNavigate();
    const screenSize = UseScreenSelectors();

    const processApplication = async (recommendersIsValid, recommendersList) => {
        setValidateRecommenders(false);

        const errors = {
            name: !candidateName?.trim(),
            surname: !candidateSurname?.trim(),
            phone: !candidatePhone?.trim() || !isPhoneValid(candidatePhone),
            email: !candidateEmail?.trim() || !isEmailValid(candidateEmail),
            arrivalWay: !arrivalWayText?.trim(),
            cvFile: !cvFile
        };

        setCandidateNameError(errors.name);
        setCandidateSurnameError(errors.surname);
        setCandidatePhoneError(errors.phone);
        setCandidateEmailError(errors.email);
        setArrivalWayError(errors.arrivalWay);
        setCvFileError(errors.cvFile);

        const hasErrors = Object.values(errors).some(error => error === true);
        if(hasErrors || cvFileExtensionError) {
            jobApplicationElement?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        if (!recommendersIsValid) {
            return;
        }

        setLoading(true);

        let newCandidateId: any = await generateCandidateId();
        let newCandidate: any = new Candidate(
            newCandidateId,
            candidateName,
            candidateSurname,
            candidatePhone,
            candidateEmail,
            -1,
            ""
        );
        const existingCandidate = (await getFilteredCandidates(["eMail", "phone"], [newCandidate._eMail, newCandidate._phone]));

        if (existingCandidate.length !== 0) {
            newCandidate = existingCandidate[0]
            newCandidateId = newCandidate._id;

            const submissions = await getFilteredCandidateJobStatuses();
            let submissions_ = submissions;
            if (submissions_.filter((submission) => submission._candidateId === newCandidateId && submission._status !== 'נדחה').length >= 2) {
                setLoading(false);
                setAlertMessage("הגשת מועמדות במקביל מוגבלת ל-2 משרות לכל היותר.");
                setAlert(true);
                return;
            }
        } else {
            await newCandidate.add();
        }

        let recommendersOnlyList: Recomendation[] = [];
        let recommendersFilesOnlyList: File[] = [];
        for (const recommender of recommendersList) {
            const recommenderInfo = recommender[0];
            const file = recommender[1];
            if (recommenderInfo) {
                recommendersOnlyList.push(recommenderInfo);
                if (!file) {
                    recommendersFilesOnlyList.push(new File([''], ''));
                } else {
                    recommendersFilesOnlyList.push(file);
                }
            }
        }

        const errorCode = (await newCandidate.apply(jobNumber, aboutText, arrivalWayText, recommendersOnlyList, recommendersFilesOnlyList));
        if (errorCode === -1) {
            setLoading(false);
            setAlertMessage("מועמדותך כבר נקלטה עבור משרה זו");
            setAlert(true);
            return;
        }

        newCandidate.uploadCv(cvFile);
        setLoading(false);
        navigate('/career/thanks');
    };

    // This will trigger validateInputs
    const handleSubmit = () => setValidateRecommenders(true);

    if(loading) {
        return <GvanimProgress loading={loading} showWaiting={true} />
    }

    return (
        <Box>
            <Box
                ref={jobApplicationElement}
                sx={{
                    marginTop: "179px",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Icon
                    sx={{width: {xs: "118px", md: "208px"}, height: {xs: "65px", md: "115px"}}}
                    component={CloudSVG}
                ></Icon>
                <Typography
                    variant={screenSize === "xs" ? "h6" : 'h1'}
                    sx={{
                        marginTop: "19px",
                        marginBottom: {xs: "38px", md: "76px"},
                        color: "primary.jobTitle",
                    }}
                >
                    השאירו פרטים כאן:
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "background.box",
                    justifyContent: "center",
                }}
            >
                {/* Details */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column", md: "row"},
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {/* Firstname and Lastname */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {xs: "column", md: "row"},
                            marginRight: {xs: "0", md: "7px"},
                            flexGrow: 1
                        }}
                    >
                        {/* Firstname */}
                        <Box>
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'}
                                        color={"primary.jobTitle"} sx={{marginTop: "15px"}}>
                                שם פרטי:
                            </Typography>
                            <TextField
                                variant='outlined'
                                placeholder='שם פרטי'
                                sx={{
                                    backgroundColor: "background.candidateDetailsTextField",
                                    '& .MuiOutlinedInput-root': {
                                        borderColor: "red",
                                        '& fieldset': {
                                            borderColor: candidateNameError ? 'error.main' : "secondary.labelText",
                                            border: "0px solid red",
                                            borderRadius: "4px"
                                        },
                                    },
                                }}
                                color={candidateNameError ? 'error' : "primary"}
                                onChange={(event) => {
                                    setCandidateNameError(false);
                                    setCandidateName(event.target.value);
                                }}
                            />
                            <Box sx={{
                                display: candidateNameError ? "flex" : "none",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "absolute"
                            }}>
                                <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                                <Typography variant='h4' color={"error.main"}>
                                    שדה זה שגוי
                                </Typography>
                            </Box>
                        </Box>

                        {/* Lastname */}
                        <Box
                            sx={{
                                marginLeft: {xs: "0", md: "7px"},
                            }}
                        >
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'}
                                        color={"primary.jobTitle"} sx={{marginTop: "15px"}}>
                                שם משפחה:
                            </Typography>
                            <TextField
                                variant='outlined'
                                placeholder='שם משפחה'
                                sx={{
                                    backgroundColor: "background.candidateDetailsTextField",
                                    border: "0px solid red",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: candidateSurnameError ? 'error.main' : "secondary.labelText",
                                            border: "0px solid red",
                                            borderRadius: "4px"
                                        }
                                    },
                                }}
                                color={candidateSurnameError ? 'error' : "primary"}
                                onChange={(event) => {
                                    setCandidateSurnameError(false);
                                    setCandidateSurname(event.target.value);
                                }}
                            />
                            <Box sx={{
                                display: candidateSurnameError ? "flex" : "none",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "absolute"
                            }}>
                                <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                                <Typography variant='h4' color={"error.main"}>
                                    שדה זה שגוי
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    {/* Phone and Email */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {xs: "column", md: "row"},

                        }}
                    >
                        {/* Phone */}
                        <Box>
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'}
                                        color={"primary.jobTitle"} sx={{marginTop: "15px"}}>
                                טלפון:
                            </Typography>
                            <TextField
                                variant='outlined'
                                placeholder='טלפון'
                                sx={{
                                    backgroundColor: "background.candidateDetailsTextField",
                                    border: "0px solid red",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: candidatePhoneError ? 'error.main' : "secondary.labelText",
                                            border: "0px solid red",
                                            borderRadius: "4px"
                                        }
                                    },
                                }}
                                color={candidatePhoneError ? 'error' : "primary"}
                                onChange={(event) => {
                                    setCandidatePhoneError(false);
                                    setCandidatePhone(event.target.value);
                                }}
                            />
                            <Box sx={{
                                display: candidatePhoneError ? "flex" : "none",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "absolute"
                            }}>
                                <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                                <Typography variant='h4' color={"error.main"}>
                                    שדה זה שגוי
                                </Typography>
                            </Box>
                        </Box>

                        {/* Email */}
                        <Box
                            sx={{
                                marginLeft: {xs: "0", md: "7px"},

                            }}
                        >
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'}
                                        color={"primary.jobTitle"} sx={{marginTop: "15px"}}>
                                אימייל:
                            </Typography>
                            <TextField
                                variant='outlined'
                                type='email'
                                placeholder='אימייל'
                                sx={{
                                    backgroundColor: "background.candidateDetailsTextField",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: candidateEmailError ? 'error.main' : "secondary.labelText",
                                            border: "0px solid red",
                                            borderRadius: "4px"
                                        }
                                    },
                                }}
                                color={candidateEmailError ? 'error' : "primary"}
                                onChange={(event) => {
                                    setCandidateEmailError(false);
                                    setCandidateEmail(event.target.value);
                                }}

                            />
                            <Box sx={{
                                display: candidateEmailError ? "flex" : "none",
                                flexDirection: "row",
                                alignItems: "center",
                                position: "absolute"
                            }}>
                                <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                                <Typography variant='h4' color={"error.main"}>
                                    שדה זה שגוי
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>

                {/* About */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        marginTop: "54px"
                    }}
                >
                    <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'} color={"primary.jobTitle"}>
                        ספרו לנו קצת עליכם:
                    </Typography>
                    <TextField
                        label="כתבו כאן..."
                        multiline
                        variant='outlined'
                        rows={10}
                        sx={{
                            width: {xs: "100%", md: "100%"},
                            marginTop: "15px",
                            backgroundColor: "background.candidateDetailsTextField",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: "0px solid red",
                                    borderRadius: "4px",
                                }
                            },
                        }}
                        inputProps={{
                            maxLength: ABOUT_MAX_LENGTH
                        }}
                        onChange={(event) => {
                            setAboutNumChars(event?.target.value.length);
                            setAboutText(event.target.value);
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            width: "100%",
                        }}
                    >
                        <Typography variant='h4'>
                            {aboutNumChars ? aboutNumChars : 0} / {ABOUT_MAX_LENGTH}
                        </Typography>
                    </Box>
                </Box>

                {/* how did you hear about us */}
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            marginTop: "24px"
                        }}
                    >
                        <Typography variant={screenSize === "xs" ? "subtitle1" : 'h4'}
                                    color={"primary.jobTitle"}>
                            איך הגעת אלינו?
                        </Typography>
                        <TextField
                            label="כתבו כאן..."
                            multiline
                            variant='outlined'
                            rows={1}
                            sx={{
                                width: {xs: "100%", md: "100%"},
                                marginTop: "15px",
                                backgroundColor: "background.candidateDetailsTextField",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "0px solid red",
                                        borderRadius: "4px",
                                    }
                                },
                            }}
                            inputProps={{
                                maxLength: ARRIVAL_MAX_LENGTH
                            }}
                            onChange={(event) => {
                                setArrivalWayChars(event?.target.value.length);
                                setArrivalWayText(event.target.value);
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <Typography variant='h4'>
                                {arrivalWayChars ? arrivalWayChars : 0} / {ARRIVAL_MAX_LENGTH}
                            </Typography>

                            <Box sx={{
                                display: arrivalWayError ? "flex" : "none",
                            }}>
                                <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                                <Typography variant='h4' color={"error.main"}>
                                    שדה זה שגוי
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* attach CV file button */}
                <Box sx={{
                    marginTop: "55px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: {xs: "center", md: "start"},
                    width: "fit-content"
                }}>
                    <Input
                        type="file"
                        inputRef={cvFileInputRef}
                        style={{display: 'none'}}
                        inputProps={{
                            accept: "application/pdf"
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const files = event.target.files!;
                            if (files && files[0] && files[0].type === "application/pdf") {
                                setCvFile(files[0]);
                                setCvFileError(false);
                                setCvFileExtensionError(false);
                            } else if (files && files[0] && files[0].size > 5 * 1024 * 1024) {
                                setCvFile(null);
                                setCvFileError(true);
                            } else {
                                setCvFile(null);
                                setCvFileExtensionError(true);
                            }
                        }}
                    />
                    <Button
                        disableRipple
                        variant='contained'
                        sx={{
                            backgroundColor: "background.cvButton",
                            borderRadius: "36px",
                            color: "primary.textBright",
                            "&:hover": {
                                backgroundColor: "background.cvButtonHover"
                            },
                            paddingTop: "21px",
                            paddingBottom: "21px",
                            paddingLeft: "65px",
                            paddingRight: "58px"

                        }}
                        onClick={() => {
                            // trigger input onChange
                            if (cvFileInputRef.current) {
                                cvFileInputRef.current.click();
                            }
                        }}

                    >
                        <Icon
                            sx={{height: "27px", width: "27px", marginRight: "16px", fontFamily: "serif"}}
                            component={UploadIconSVG}
                        />
                        <Box

                            sx={{
                                flexDirection: "column"

                            }}
                        >
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h3'}>
                                צירוף קורות חיים
                            </Typography>


                        </Box>
                    </Button>
                    {/* display filename to the user */}
                    <Typography variant='h5'>
                        {cvFile ? "קובץ שצורף: " : ""}
                        {cvFile?.name.slice(0, 20)}
                        {cvFile?.name.length! > 20 ? '...' : ''}
                    </Typography>
                    <Box sx={{
                        display: cvFileError ? "flex" : "none",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                        <Typography variant='h4' color={"error.main"}>
                            אנא צרפו קובץ PDF שלא עולה על 5MB
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: cvFileExtensionError ? "flex" : "none",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <ErrorOutlineRounded sx={{fontSize: "24px", color: "error.main"}}/>

                        <Typography variant='h4' color={"error.main"}>
                            אנא צרפו קובץ PDF בלבד
                        </Typography>
                    </Box>
                </Box>

                <RecommendersForm validateTrigger={validateRecommenders} onValidationComplete={processApplication}/>

                {/* Separator for the submit button */}
                <Box sx={{
                    height: "1px",
                    width: "100%",
                    borderRadius: "1px",
                    backgroundColor: "background.JobDetailsText",
                    marginTop: "39px"
                }}/>

                {/* Submit Button */}
                <Box
                    sx={{
                        width: "100%",
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: "537px",
                        marginTop: "76px",
                    }}
                >

                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: "background.JobDetailsText",
                            borderRadius: "36px",
                            paddingTop: "21px",
                            paddingBottom: "21px",
                            paddingRight: {xs: "84px", md: "8vw"},
                            paddingLeft: {xs: "84px", md: "8vw"},
                            color: "primary.main",
                            "&:hover": {
                                backgroundColor: "background.submitButtonHover"
                            }
                        }}
                        onClick={handleSubmit}
                    >
                        <Typography variant='h4' color={"primary.textBright"}>
                            שלח/י טופס
                        </Typography>
                    </Button>
                </Box>
            </Box>

            <CustomDialog open={alert} setOpen={setAlert} handleFailureClose={() => {
            }} handleSuccessClose={() => {
            }} title={"התראה"} description={alertMessage} okayLabel={"אני מבין"} closeLabel={null}/>
        </Box>
    );
}
