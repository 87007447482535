import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Navigate, Outlet, Route, Routes, useLocation, useRoutes} from 'react-router-dom';
import Auth from './RecruiterMainPage/Components/Auth/Auth';
import PasswordRecover from './RecruiterMainPage/RecoveryPasswordPage/RecoveryPasswordPage'
import UpdateAccount
    from './RecruiterMainPage/Components/AdminPage/Components/TabsPanel/Components/ManageAccount/Components/AccountSettings/UpdateAccount/UpdateAccount';
import React from 'react';
import DrushimThemeProvider from './DrushimMainPage/Theme/ThemeProvider';
import Navbar from './DrushimMainPage/Layout/Navbar/Navbar';
import {Box, CssBaseline} from '@mui/material';
import Footer from './DrushimMainPage/Layout/Footer/Footer';
import MainPage from "./DrushimMainPage/Pages/MainPage/MainPage";
import JobPage from "./DrushimMainPage/Pages/JobPage/JobPage";
import ThanksPage from "./DrushimMainPage/Pages/ThanksPage/ThanksPage";
import PageNotFound from "./DrushimMainPage/Pages/NotFoundPage/NotFoundPage";
import WelcomePage from './RecruiterMainPage/WelcomePage/WelcomePage';
import ManageJobsPage from "./RecruiterMainPage/ManageJobsPage/ManageJobsPage";
import NewJobPage from "./RecruiterMainPage/ManageJobsPage/Components/NewJobPage/NewJobPage";
import EditCandidate
    from "./RecruiterMainPage/ManageCandidatesPage/ViewCandidatesPage/Components/EditCandidate/EditCandidate";
import ManageCandidatesPage from "./RecruiterMainPage/ManageCandidatesPage/ManageCandidatesPage";
import NewCandidatePage from "./RecruiterMainPage/ManageCandidatesPage/CreateCandidatePage/NewCandidatePage";
import ViewCandidatePage from "./RecruiterMainPage/ManageCandidatesPage/ViewCandidatePage/ViewCandidatePage";
import ManageInterviewsPage
    from "./RecruiterMainPage/ManageCandidatesPage/ViewCandidatesPage/ManageInterViewsPage/ManageInterViewsPage";
import ReportsPage from "./RecruiterMainPage/ReportsPage/ReportsPage";
import JobsFiltersForm from "./RecruiterMainPage/ReportsPage/Components/Reports/JobsFitersForm";
import CandidateFiltersForm from "./RecruiterMainPage/ReportsPage/Components/Reports/CandidatesFiltersForm";
import AdminPage from "./RecruiterMainPage/Components/AdminPage/AdminPage";
import Accessibility from "./DrushimMainPage/Components/Accessibility/Accessibility";

const recruitersPageTheme = createTheme({
    direction: 'rtl',
});

const AppRoutes = () => useRoutes([
    {
        path: '/career',
        element: (
            <DrushimThemeProvider>
                <CssBaseline/>
                <Navbar/>
                <Box flex={1} height={'calc(100dvh - 64px)'}>
                    <Accessibility/>
                    <Outlet/>
                    <Footer/>
                </Box>
            </DrushimThemeProvider>
        ),
        children: [
            {path: '', element: <Navigate to={"/career/jobs"}/>},
            {path: 'jobs', element: <MainPage/>},
            {path: 'jobs/:id', element: <JobPage/>},
            {path: 'thanks', element: <ThanksPage/>},
            {path: 'not-found', element: <PageNotFound/>}
        ]
    },
    {path: "/career/*", element: <Navigate to={"/career/not-found"}/>},
    {path: '', element: <Navigate to={"/career/jobs"}/>},
    {
        path: "management", element: (
            <ThemeProvider theme={recruitersPageTheme}>
                <Auth/>
            </ThemeProvider>
        ),
        children: [
            {path: "", element: <WelcomePage/>},
            {path: "manageJobs", element: <ManageJobsPage/>},
            {path: "createJob", element: <NewJobPage/>},
            {path: "editCandidate", element: <EditCandidate/>},
            {path: "manageCandidates", element: <ManageCandidatesPage/>},
            {path: "createCandidate", element: <NewCandidatePage/>},
            {path: "manageCandidates/:candidateId", element: <ViewCandidatePage/>},
            {path: "manageCandidates/:candidateId/interviews", element: <ManageInterviewsPage/>},
            {path: "reports", element: <ReportsPage/>},
            {path: "reports/JobsByFilters", element: <JobsFiltersForm/>},
            {path: "reports/CandidateByFilters", element: <CandidateFiltersForm/>},
            {path: "settings", element: <AdminPage/>},
        ]
    },
    {path: "/settings/updateAccount", element: <UpdateAccount/>},
    {path: "/passwordRecovery", element: <PasswordRecover/>}
]);

export default function App() {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (<AppRoutes/>);
}
