import {CssBaseline} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, Outlet, Route, Routes} from "react-router-dom";

import
{
    Candidate,
    getFilteredCandidates,
} from "../Firebase/FirebaseFunctions/Candidate";
import {getFilteredJobs} from "../Firebase/FirebaseFunctions/Job";
import AdminPage from "./Components/AdminPage/AdminPage";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import NewCandidatePage from "./ManageCandidatesPage/CreateCandidatePage/NewCandidatePage";
import ManageCandidatesPage from "./ManageCandidatesPage/ManageCandidatesPage";
import EditCandidate from "./ManageCandidatesPage/ViewCandidatesPage/Components/EditCandidate/EditCandidate";
import ManageInterviewsPage from "./ManageCandidatesPage/ViewCandidatesPage/ManageInterViewsPage/ManageInterViewsPage";
import ViewCandidatesPage from "./ManageCandidatesPage/ViewCandidatesPage/ViewCandidatesPage";
import NewJobPage from "./ManageJobsPage/Components/NewJobPage/NewJobPage";
import ManageJobsPage from "./ManageJobsPage/ManageJobsPage";
import CandidateFiltersForm from "./ReportsPage/Components/Reports/CandidatesFiltersForm";
import JobsFiltersForm from "./ReportsPage/Components/Reports/JobsFitersForm";
import ReportsPage from "./ReportsPage/ReportsPage";
import WelcomePage from "./WelcomePage/WelcomePage";
import ViewCandidatePage from "./ManageCandidatesPage/ViewCandidatePage/ViewCandidatePage";

function RecruiterMainPage({handlelogout}) {
    const [allJobs, setAllJobs] = React.useState<any[]>([]);
    const [candidateIDs, setCandidateIDs] = useState<string[]>([]);
    const [updatedCandidates, setUpdatedCandidates] = useState<Candidate[]>([]);

    const fetchAllJobs = async () => {
        const jobs = await getFilteredJobs();
        const jobsWithId = jobs.map((job) => ({...job, id: job._jobNumber}));
        setAllJobs(jobsWithId);
    };

    const fetchCandidateIDs = async () => {
        const candidates = await getFilteredCandidates();
        setCandidateIDs(candidates.map((candidate) => candidate._id));
    };

    useEffect(() => {
        fetchAllJobs();
        fetchCandidateIDs();
    }, []);

    return (
        <>
            <CssBaseline/>
            <NavBar handlelogout={handlelogout}/>
            {allJobs.map((job) => (
                <Link key={job.id} to={"/management/jobs/" + job.id}/>
            ))}
            <Outlet/>
            <Footer/>
        </>
    );
}

export default RecruiterMainPage;
