import { Avatar, Box, Typography } from '@mui/material';
import { Job } from '../../../../../../Firebase/FirebaseFunctions/Job';
import Logo from "../../../../../../Components/Logo/logo.png";
import dayjs from 'dayjs';


export default function JobItem(props: { job: Job }) {
    const { job } = props;

    return (
        <Box width='100%' display='flex' flexDirection='row' justifyContent='center'>
            <Box minWidth={{ xs: '100%', md: '400px' }} display='flex' flexDirection={{ xs: 'column', md: 'row' }}
                sx={{

                    border: '1px solid rgba(0, 0, 0, 0.125)'
                }}

                p={0}>
                <Box
                //  sx={{ border: '1px solid gray' }}
                >
                    <Avatar variant='square' src={Logo} sx={{
                        width: { xs: '100%', md: 150 }, height: { xs: 90, md: 150 }, padding: 1, background: '#fafaf8',
                        // border: '1px solid #dee2e6',
                        '& .MuiAvatar-img': {
                            objectFit: 'contain',
                        }

                    }} />
                </Box>
                <Box
                    display='flex'
                    flexDirection='column'
                    gap={0.5}
                    pr={2}
                    pl={2}
                    pt={1}
                // sx={{ border: '1px solid gray' }}
                >
                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography component={'span'} fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            כותרת המשרה:
                        </Typography>

                        <Typography component={'span'} fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                            {job?._title}
                        </Typography>
                    </Box>

                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            תפקיד:
                        </Typography>

                        <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                            {job?._role}
                        </Typography>
                    </Box>

                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            מס' המשרה:
                        </Typography>

                        <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                            {job?._jobNumber}
                        </Typography>
                    </Box>

                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            איזור:
                        </Typography>

                        <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                            {job?._region}
                        </Typography>
                    </Box>


                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            אשכול:
                        </Typography>

                        <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                          {job?._sector}
                        </Typography>
                    </Box>

                    <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                        <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                            נוצרה בתאריך:
                        </Typography>
                        
                        <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                            {dayjs(job?._creationDate).format("DD/MM/YYYY").toString()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}
