import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import DialogPage from './Components/DialogPage/DialogPage';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CandidatesListFullScreenDialog(props: { JobId: any }) {
	const { JobId } = props;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				variant='text'
				disableRipple
				style={{ backgroundColor: 'transparent' }}
				endIcon={<ChevronLeft />}
				onClick={handleClickOpen}
			>
				לרשימת המועמדים
			</Button>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative', background: 'linear-gradient(310deg, #7795f8,#555abf)' }}>
					<Toolbar sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Box sx={{ width: '100%', height: '100%' }}>
					<DialogPage JobId={JobId} />
				</Box>
			</Dialog>
		</React.Fragment>
	);
}